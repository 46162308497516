// Customizable Area Start

import React, { FunctionComponent, ReactNode } from "react";
import { styled } from "@material-ui/styles";
import { Box, Button, TextField, Typography } from "@material-ui/core";

import GenericCard from "../../../components/src/GenericCard.web";
import GenericButton from "../../../components/src/GenericButton.web";

export default class ForgotPassword extends React.Component {
    constructor(props: any) {
        super(props)
    }

    render() {
        return (
            <GenericCard>
                <Layout>
                    <BackButton>{`< Back`}</BackButton>
                    <Wrapper>
                        <Header>
                            Forgot Password
                        </Header>
                        <Description>
                            Enter the email address associated with your account <br></br>
                            and we’ll send you a link to reset your password.
                        </Description>
                        <Label>EMAIL ADDRESS</Label>
                        <TextField variant="outlined" style={emailInputStyle}></TextField>
                        <GenericButton buttonLabel="SUBMIT"></GenericButton>
                    </Wrapper>
                </Layout>
            </GenericCard>
        )
    }
}

const Layout = styled(Box)({
    display: "flex",
    flexDirection: "column",
    width: "100%"
});

const Wrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    width: 400,
    alignSelf: "center"
});

const BackButton = styled(Button)({
    display: "flex",
    justifyContent: "flex-start",
    color: "#1E1E1E",
    fontSize: 14,
    fontWeight: 700,
    width: "max-content"
});

const Header = styled(Typography)({
    color: "#1E1E1E",
    fontSize: "28px",
    forntWeight: 700,
    textAlign: "center",
    marginTop: 32,
    marginBottom: 24
});

const Description = styled(Typography)({
    color: "#363636",
    fontSize: "14px",
    forntWeight: 500,
    textAlign: "center",
    marginBottom: 24
});

const Label = styled(Typography)({
    color: "#363636",
    fontSize: "14px",
    fontWeight: 700
});

const emailInputStyle = {
    borderRadius: 8,
    border: "1px solid #CDCDCD",
    height: 56,
    gap: 8,
    marginBottom: 8
};

// Customizable Area End
