import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
    stepLabels: Array<string>;
    activeStep : number;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      stepLabels : [],
      activeStep : 0,
      // Customizable Area End
    };
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let temp = ["Organization Details", "Principal Organization Details", "Admin’s Profile", "Review &Submit"]
    this.setState({
        stepLabels : temp
    })
  }

   handleNext = () => {
    this.setState({activeStep : this.state.activeStep + 1})
  };

   handleBack = () => {
    this.setState({activeStep : this.state.activeStep - 1})
  };

   handleReset = () => {
    this.setState({activeStep : 0})
  };
  // Customizable Area End
}