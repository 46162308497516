import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import GenericCard from "../../../components/src/GenericCard.web";
import AppBackground from "../../../components/src/AppBackground.web";

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLogin extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderInput = ()=> {
    return (
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 0px",
      }}>
        <Box>
          <Typography>
            <EmailLabel style={{ textAlign: "left" }}> EMAIL ADDRESS</EmailLabel>
          </Typography>
          <TextField variant="outlined"
            style={{
              width: "366px",
              height: "56px",
              borderRadius: "8px",
              border: "1px",
              marginTop: "10px"
            }}
            value={this.state.email}
            onChange={(e) => this.setEmail(e.target.value)}
          />
        </Box>
        <Box>
          <Typography>
            <PasswordLabel>PASSWORD</PasswordLabel>
          </Typography>
          <TextField
            variant="outlined"
            style={{
              width: "366px",
              height: "56px",
              borderRadius: "8px",
              border: "1px",
              marginTop: "10px",
            }}
            id="Password"
            name="password"
            type={this.state.enablePasswordField ? 'text' : 'password'}
            inputProps={{ maxLength: 12 }}
            value={this.state.password}
            onChange={(e) => this.setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPasswords}
                    edge="end"
                  >
                    {this.state.enablePasswordField ? (
                      <VisibilityOff style={{ color: "#000000" }} />
                    ) : (
                      <Visibility style={{ color: "#000000" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    )
  }
  renderButton = () => {
    return (
      <Box>
        <Box
          data-test-id={"btnForgotPassword"}
          sx={{
            fontSize: "16px",
            color: "#1653DD",
            fontWeight: 700,
            textAlign: "right",
            marginRight: "90px"
          }}
          onClick={() => this.goToForgotPasswordBlog()}
        >
          Forgot Password?
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px 0px",
          }}
        >
          <Button
            data-test-id={"btnEmailLogIn"}
            variant="contained"
            color="primary"
            onClick={() => this.doEmailLogIn()}
            style={{ width: "264px", height: "56px", borderRadius: "45px" }}>
            Login {/*UI Engine::From Sketch */}
          </Button>
        </Box>

      </Box>
    )
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <AppBackground>
        <GenericCard>

          <ThemeProvider theme={theme}>
            <Container maxWidth="sm">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
              >
                <Typography component="h2" variant="h4">
                  <LabelTitle>{this.labelTitle}</LabelTitle>
                </Typography>
                <Box sx={{ width: "100%", paddingTop: "20px" }}>
                {this.renderInput()}
                  {this.renderButton()}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "10px 0px",
                    }}
                  >
                  </Box>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </GenericCard>
      </AppBackground>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const EmailLabel = styled(Box)(
  {
    color: "#363636",
    fontWeight: 700,
    fontSize: "14px",
    marginTop: "5px"
  }
)
const PasswordLabel = styled(Box)(
  {
    color: "#363636",
    fontWeight: 700,
    fontSize: "14px",
    marginTop: "10px"
  }
)
const LabelTitle = styled(Box)(
  {
    fontWeight: 700,
    fontSize: "21px",
    color: "#1E1E1E"
  }
)
// Customizable Area End
