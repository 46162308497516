// Customizable Area Start

import { styled } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import GenericCard from "../../../components/src/GenericCard.web";
import ForgotPasswordController from "./ForgotPassword.web.controller";
import AppBackground from "../../../components/src/AppBackground.web";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: any) {
    super(props)
  }

  render() {
    return (
      <AppBackground>
        <GenericCard>
          <Layout>
            <Wrapper>
              <CheckCircleIcon style={IconStyle} />
              <Header>
                Reset link sent!
              </Header>
              <Description>
                We've just emailed you the password reset link.
              </Description>
            </Wrapper>
          </Layout>
        </GenericCard>
      </AppBackground>
    )
  }
}

const Layout = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%"
});

const Wrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: 400,
  alignSelf: "center"
});

const Header = styled(Typography)({
  color: "#1E1E1E",
  fontSize: "28px",
  fontWeight: 700,
  textAlign: "center",
  marginTop: 32,
  marginBottom: 24
});

const Description = styled(Typography)({
  color: "#363636",
  fontSize: "14px",
  forntWeight: 500,
  textAlign: "center",
  marginBottom: 24
});

const IconStyle = {
  height: 100,
  width: 100,
  color: "#1653DD",
  alignSelf: "center"
}

// Customizable Area End