//@ts-nocheck
// Customizable Area Start

import { BlockComponent } from "../../../framework/src/BlockComponent";

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    // Customizable Area Start
    emailId: string,
    isEmailVerified: boolean
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
    Props,
    S,
    SS
>  {
    constructor(props: any) {
        super(props)
        this.state = {
            // Customizable Area Start
            emailId: "",
            isEmailVerified: true
            // Customizable Area End
        }
    }

    handleEmailChange = (e: any) => {
        this.setState({ emailId: e.target.value })
        this.setState({ isEmailVerified: true })
    }

    handleSubmit = () => {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        if (this.state.emailId !== "" && emailRegex.test(this.state.emailId)) {
            console.log("Email id")
        }
        else {
            this.setState({ isEmailVerified: false })
        }
    }
}

// Customizable Area End