// Customizable Area Start
import * as React from "react";
const AlphaLogo = require("./image_logo.png")
const backgroundImg = require("./image_bg.png");
import { Box, Grid, Button } from "@material-ui/core";
import { styled } from "@material-ui/styles";

interface PermanentDrawerLeftProps {
  children?: React.ReactNode;
  loader?: boolean;
}

export default class AppBackground extends React.Component {
  constructor(props: PermanentDrawerLeftProps) {
    super(props)
  }
  render() {
    return (
      <Background>
        <img src={AlphaLogo} alt="logo"/>
        {this.props.children}
      </Background>
    );
  }
}
const Background = styled(Box)({
  height: "100vh",
  backgroundImage: `url(${backgroundImg})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center", // Center the background image
  width: "100vw"
});

// Customizable Area End