// Customizable Area Start
import React, { FunctionComponent, ReactNode } from "react";
import { styled } from "@material-ui/styles";
import { Box } from "@material-ui/core";

interface Props {
    children: ReactNode;
}

class GenericCard extends React.Component {
    constructor(props: Props) {
        super(props)
    }
    render() {
        return (
            <CardLayout>
                <CardWraper>
                    {this.props.children}
                </CardWraper>
            </CardLayout>
        );
    }
};

export default GenericCard;

const CardLayout = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto"
});

const CardWraper = styled(Box)({
    borderRadius: "24px",
    backgroundColor: "#FFF",
    padding: 12,
    width: "828px",
    display: "flex",
    justifyContent: "center"
});

// Customizable Area End