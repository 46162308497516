// Customizable Area Start
import React, { Component, FunctionComponent, MouseEvent } from "react";
import { styled } from "@material-ui/styles";
import { Button } from "@material-ui/core";

interface MyProps {
    buttonLabel: string;
    buttonOnClick?: (e: MouseEvent<HTMLElement>) => void
}

class GenericButton extends Component<MyProps> {
    constructor(props: any) {
        super(props)
    }

    render() {
        return (
            <>
                <CustomButton onClick={this.props.buttonOnClick}>
                    {this.props.buttonLabel}
                </CustomButton>
            </>
        );
    }
};

export default GenericButton;

const CustomButton = styled(Button)({
    backgroundColor: "#1653DD",
    paddingLeft: 58,
    paddingRight: 58,
    paddingTop: 16,
    paddingBottom: 16,
    color: "#FFF",
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: "1.28px",
    borderRadius: "45px",
    '&:hover': {
        backgroundColor: '#1653DD',
        color: '#FFF',
    },
});

// Customizable Area End