import React from "react";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import Typography from '@material-ui/core/Typography';
import AppBackground from "../../../components/src/AppBackground.web";
import GenericCard from "../../../components/src/GenericCard.web";
import OrganizationSignUpController from "./OrganizationSignUpController.web";

export default class OrganizationSignUp extends OrganizationSignUpController {
  constructor(props:any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    return (
      
      // Required for all blocks
     <AppBackground>
      <GenericCard>
      <div >
      <Stepper activeStep={this.state.activeStep} alternativeLabel>
        {this.state.stepLabels.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {this.state.activeStep === this.state.stepLabels.length ? (
          <div>
            <Typography >All steps completed</Typography>
            <Button onClick={this.handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
             <Formik
        initialValues={{ email: '' }}
        onSubmit={values => {
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <label htmlFor="email">Email Address</label>
            <input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />

            <button type="submit">Submit</button>
          </form>
        )}
      </Formik>
          </div>
        )}
      </div>
    </div>
      </GenericCard>

     </AppBackground>
    );
}
}
// Customizable Area End